<template>
  <ui-component-modal
    modalTitle="Edit to-do"
    :isSaving="isSaving"
    :onClickCancel="onClickCancel"
    :onClickSave="editToDo"
    :showModal="showModal"
    :hideFooterButtons="isDoneSaving"
    :buttonLabelConfirm="'Save'"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <section
        v-if="!isDoneSaving"
        class="section"
      >
        <div class="container">
          <div>
            <span>To-do</span>
            <input
              type="text"
              v-model="mToDo.Subject"
              v-on:keyup.13="editToDo"
              class="input"
            />
          </div>

          <div>
            <textarea
              v-model="mToDo.Body"
              maxlength="6000"
            ></textarea>
          </div>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import todoProvider from '@/providers/todo'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    todo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDoneSaving: false,
      isSaving: false,
      mToDo: this.todo,
    }
  },

  methods: {
    editToDo() {
      let self = this

      self.isDoneSaving = false

      if (self.mToDo.Subject.trim() !== '') {
        self.isSaving = true

        todoProvider.methods
          .updateToDo(self.mToDo)
          .then((response) => {
            if (response.status === 200) {
              self.$emit('', this.mToDo)

              self.isDoneSaving = true

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
